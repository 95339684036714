export function initPuzzelChat() {

  let isJobListPage = document.querySelector('body.vacancylandingpage') !== null;
  let isJobPage = document.querySelector('body.vacancypage') !== null;
  let language = document.querySelector('html').getAttribute('lang');


  //only use chat on swedish and danish job pages
  if (language !== 'sv' || isJobPage || isJobListPage)
    return;

  window.addEventListener('CookiebotOnAccept', function (e) {
    if (Cookiebot.consent.statistics) {
        (function(a, b, c) {
          var loader = a.createElement('script');
                  loader.src = 'https://euwa.puzzel.com/loader/index.js';
                  a.body.append(loader);
                  loader.onload = function () {
                    new EUWALoader({ customerKey: b, configId: c }).load();
          };
          })(document, '4609071', 'eeb5ec5e-9794-416e-8815-ca267cff0c6e');
    }
  });

}
