export function initZmashbot() {

  let isJobListPage = document.querySelector('body.vacancylandingpage') !== null;
  let isJobPage = document.querySelector('body.vacancypage') !== null &&
                  document.querySelector('.vacancypage__recruitmentbox').getAttribute('data-adid')?.trim();
  let language = document.querySelector('html').getAttribute('lang');


  //only use chat on swedish and danish job pages
  if (!isJobListPage && !isJobPage || language !== 'sv' && language !== 'da')
    return;


  let additionalData = {
    lang: language
  }

  let jobId = -1;
  if (isJobPage) {
    jobId = document.querySelector('.vacancypage__recruitmentbox').getAttribute('data-adid');
    additionalData.jobId = jobId;
  }

  const localizationData = {
    sv: { title: 'Ansök här utan CV!', guid: '369ec237-5596-404e-8b7a-15b3b6c47953', id: 1 },
    da: { title: 'Ansøg uden CV!', guid: 'b1c2b74a-5374-46a3-84ea-c406eb8d0d19', id: 0 },
  };

  const chatbotTitle = localizationData[language].title || 'sv';

  window.addEventListener('CookiebotOnAccept', function (e) {
    if (Cookiebot.consent.marketing) {
        (function (z, m, a, s, h, b, o, t, c, ha, tb, ot, sc, ri, pt) {
          z = z[s] = z[s] || {}; z.biid = h; z.type = sc;
          s = m.getElementsByTagName(a)[0]; m = m.createElement(a); m.async = !0; m.src = "https://bot.zmashsolutions.com/bot.js"; z.himg = b || null; z.aimg = o || null; s.parentNode.insertBefore(m, s);
          z.cc = t || null; z.cta = c || null; z.ao = ha || !1; z.ani = tb.a || !1; z.ctaBg = tb.b || null; z.ctaCc = tb.c || null; z.dlp = ot; z.zIndex = ri; z.initParams = pt || null
        })
          (window, document, 'script', 'zmashBot', localizationData[language].guid,
            'https://zmash.com/wp-content/uploads/2022/08/bravida_logo_cmyk_neg-2.png', 'https://zmash.com/wp-content/uploads/2022/08/bravida-avatar2.png',
            '006390', chatbotTitle, { d: 1, m: 0, scc: 0 }, { a: false, b: '006390', c: 'ffffff' }, localizationData[language].id, 'a', 999999999, additionalData);


    }
  });

}
